import React from 'react';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores/app';

type Props = {
  cardsNumber?: number;
};

export const CharectersListSkeleton: React.FC<Props> = ({ cardsNumber = 10 }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-2 sm:gap-[12px]">
      {Array.from(new Array(cardsNumber)).map((item, i) => (
        <div
          key={i}
          className="skeleton bg-black-100 w-auto h-[300px] lg:h-[300px] lg:w-[230px] xl:h-[300px] xl:w-[250px]"
        ></div>
      ))}
    </div>
  );
};
