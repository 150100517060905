import React from 'react';
import { useRouter } from 'next/router';
import { cn } from '@/lib/utils';
import { Text } from '@/components/Text';
import { Banner as IBanner } from '@/models/banner';
import { Button } from '@/components/buttons/Button';
import Image from 'next/image';
import posthog from 'posthog-js';

type Props = {
  className?: string;
  banner: IBanner;
};

export const Banner: React.FC<Props> = ({ className, banner }) => {
  const { push } = useRouter();

  const onClickHandler = () => {
    if (!banner?.call_to_action_url) return;
    const url = banner.call_to_action_url;
    const isExternal = url.startsWith('http://') || url.startsWith('https://');

    if (isExternal) {
      window.open(url, '_blank');
    } else {
      push(url);
    }
    window.gtag && window.gtag('event', 'view_banner_click', {url: url});
    posthog.capture('view_banner_click', {url: url});
  };

  if (!banner) return null;

  return (
    <div className={cn('relative flex w-full', className)}
        onClick={onClickHandler}
        style={{ cursor: banner?.call_to_action_url ? 'pointer' : 'default' }}
    >      
      <div className="flex w-full">
        {banner?.asset_url && (
          <Image className="object-cover" 
          alt="banner"
          src={banner?.asset_url} 
          layout="responsive"
          objectFit="cover"
          width={100}
          height={100}
          quality={100}
          priority 
        />
        )}
      </div>
    </div>
  );
};
