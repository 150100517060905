import { AnonymousBanner } from '@/components/AnonymousBanner';
import { SidebarLayout } from '@/components/layout/SidebarLayout';
import { Text } from '@/components/Text';
import { CharactersList } from '@/components/page-components/home/CharactersList';
import { CharectersListSkeleton } from '@/components/page-components/home/CharectersListSkeleton';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useCharactersStore } from '@/stores/characters';
import { useAppStore } from '@/stores/app';
import { BannersCarousel } from '@/components/page-components/home/BannersCarousel';

export default function Home() {
  const { characters, loading } = useCharactersStore(s => ({
    characters: s.characters,
    loading: s.loading,
  }));
  const systemCharacters = characters.filter(c => c.created_by_user === false);
  const { banners, bannersLoading } = useAppStore(s => s);
  const isLoggedIn = useIsAuthenticated();

  return (
    <SidebarLayout>
      {banners && banners.length > 0 && (
        <div className={'mb-8'}>
          <BannersCarousel banners={banners} loading={bannersLoading} />
        </div>
      )}
      <div className="flex flex-col">
      </div>
      <div className="flex flex-col">
        <Text className="mb-5 text-h6 md:text-h3" variant="h3" textColor="white" element="h1">
        ⚠️🔞 18+ NSFW Chat Scenarios 🔞⚠️
        </Text>
        {systemCharacters && <CharactersList characters={systemCharacters} />}
        {!systemCharacters?.length && loading && <CharectersListSkeleton />}
        {!isLoggedIn && !loading && <AnonymousBanner />}
      </div>
    </SidebarLayout>
  );
}
