import React from 'react';
import { CharacterCard } from './CharacterCard';
import { cn } from '@/lib/utils';
import { ICharacter } from '@/models/characters';
import { useState, useEffect } from 'react';

// Define the interval duration as a constant
// 5 seconds, each card has their own chance of actually triggering the action
const ACTION_TRIGGER_INTERVAL = 5000;

type Props = {
  characters: ICharacter[];
  beforeCards?: React.ReactNode;
  afterCards?: React.ReactNode;
};

export const CharactersList: React.FC<Props> = ({ characters, beforeCards, afterCards }) => {
  const [triggerAction, setTriggerAction] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle the state to trigger action repeatedly
      setTriggerAction(prev => !prev);
    }, ACTION_TRIGGER_INTERVAL);

    return () => clearInterval(interval); // Cleanup the interval
  }, []);

  return (
    <div
      className={cn(
        'grid h-fit w-full grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-[12px]',
      )}
    >
      {beforeCards}
      {characters && characters.map((char, i) => (
        <CharacterCard key={char.id} character={char} triggerAction={triggerAction} />
      ))}
      {afterCards}
    </div>
  );
};
